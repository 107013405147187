const downArrow = document.querySelector('.container__icon-container')
const landingGif = document.querySelector('.image-sequence-slide-container')
const clientCheck = document.querySelector('div.text-slide__left-column > h1')
let holiday = document.querySelector('.holiday__landing')

if (clientCheck && clientCheck.innerText == "Clients") {
  downArrow.style.display = "none"
}

const elements = [downArrow, landingGif, holiday]
elements.forEach(addSlideDownEventListener)

function addSlideDownEventListener(element) {
  if (element) {
    element.addEventListener('click', ()=> {
      if (scrollY + window.innerHeight + 1 <= (document.body.scrollHeight)) {
        let slides = document.querySelectorAll('.slide-container')
        let heights = Array.from(slides).map((el)=> {
          return el.clientHeight
        })

        for (let i = 1; i < heights.length; i++) {
          heights[i] = heights[i - 1] + heights[i]
        }

        let h = 0
        let currentScroll = window.scrollY

        heights.forEach((height)=> {
          if (currentScroll < height ) {
            window.scrollTo({ top: heights[h], left: 0, behavior: 'smooth' });
            return
          }
          h++
        })
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }
    })
  }
}

window.addEventListener('fullpage-destroyed', () => {
  holiday = document.querySelector('.holiday__landing')
  addSlideDownEventListener(holiday)
})
