// var hamburgerIconClickArea = document.querySelectorAll('.navigation')
var hamburgerIcons = document.querySelectorAll('.navigation__icon')
var navMenu = document.getElementById("navigation-background")
var work = document.querySelector('#navigation-background > div > div.navigation__left > ul > li:nth-child(4) > a')


document.body.addEventListener('click', function(e){
  const isHamburger = (e.target.classList.contains('navigation__icon') || e.target.classList.contains('navigation'));
  if(isHamburger){
    toggleNavMenu()
  }
})


work.addEventListener('click', toggleNavMenu)

function toggleNavMenu () {
  navMenu.classList.toggle("menu-open");
  hamburgerIcons.forEach((icon)=> {
    icon.classList.toggle("hamburger-close-icon")
  })
}
