const videoThumbnails = document.querySelectorAll('.case-study-mobile__video-thumbnail, .case-study-desktop__video-thumbnail')
const overlay = document.querySelector('.overlay')
const overlayVideo = document.querySelector('.overlay__video')
let iframe = null

Array.from(videoThumbnails).forEach((thumb) => {
  thumb.addEventListener('click', function() {
    openVideoOverlay(thumb.dataset.vimeoVideoId)
  })
})

if (overlay) {
  overlay.addEventListener('click', function() {
    closeVideoOverlay()
  })
}

function openVideoOverlay(vimeoVideoId) {
  addVideoIframe(vimeoVideoId)
  overlay.classList.remove('overlay__hidden')
}

function closeVideoOverlay() {
  overlay.classList.add('overlay__hidden')
  removeVideoIframe()
}

function addVideoIframe(vimeoVideoId) {
  createIframe(vimeoVideoId)
  overlayVideo.appendChild(iframe)
}

function createIframe(vimeoVideoId) {
  iframe = document.createElement('iframe')
  iframe.setAttribute('frameborder', 0)
  iframe.setAttribute('webkitallowfullscreen', true)
  iframe.setAttribute('mozallowfullscreen', true)
  iframe.setAttribute('allowfullscreen', true)
  iframe.setAttribute('src', vimeoVideoSrc(vimeoVideoId))
}

function vimeoVideoSrc(vimeoVideoId) {
  return `https://player.vimeo.com/video/${vimeoVideoId}?autoplay=1`
}

function removeVideoIframe() {
  iframe.remove()
  iframe = null
}
