const FIRST_SLIDE_DELAY = 2000
const SLIDE_DELAY = 400

const imageSequenceSlides = document.querySelectorAll('.image-sequence-slide')

if (imageSequenceSlides.length > 1) {
  Array.from(imageSequenceSlides).forEach((slide) => {
    const slideImages = slide.querySelectorAll('img')
    let state = { currentImageIndex: 0 }
    nextSlide(slideImages, state)
  })
}

function nextSlide(slideImages, state) {
  let delay = SLIDE_DELAY
  if (state.currentImageIndex === 0) { delay = FIRST_SLIDE_DELAY }

  Array.from(slideImages).forEach((slide, i) => {
    if (state.currentImageIndex === i) {
      slide.classList.remove('hidden')
    } else {
      slide.classList.add('hidden')
    }
  })
  nextSlideIndex(slideImages, state)
  setTimeout(() => nextSlide(slideImages, state), delay)
}

function nextSlideIndex(slideImages, state) {
  state.currentImageIndex += 1
  if (state.currentImageIndex >= slideImages.length) {
    state.currentImageIndex = 0
  }
}
