const onCaseStudyPage = document.querySelector('.case-study')
const onHolidayPage = document.querySelector('.holiday')

if (onCaseStudyPage || onHolidayPage) {
  document.querySelector('.down-arrow').style.display = "none"
  document.querySelector('.up-arrow').style.display = "none"
}

document.querySelector('.down-arrow').style.display
document.querySelector('.up-arrow').style.display = "none"

var isScrolling;

var headers = document.querySelectorAll('.header-container');
var arrow = document.querySelector('.container__icon-container')
var downArrow = document.querySelector('.down-arrow')
var upArrow = document.querySelector('.up-arrow')

var onScroll = () => {
  headers.forEach((header)=> {
    header.classList.add('hide')
  })

  arrow.classList.add('container__icon-container__hide')

  window.clearTimeout( isScrolling );
  isScrolling = setTimeout(function() {
    headers.forEach((header)=> {
      header.classList.remove('hide')
    })

    arrow.classList.remove('container__icon-container__hide')
  }, 100);

  if (onCaseStudyPage || onHolidayPage) {
    downArrow.style.display = "none"
  }

  if (scrollY + window.innerHeight >= document.body.scrollHeight) {
    downArrow.style.display = "none"
    upArrow.style.display = "initial"
  } else {
    downArrow.style.display = onCaseStudyPage ? "none" : "initial"
    upArrow.style.display = "none"
  }
}

window.addEventListener('scroll', onScroll, false)
