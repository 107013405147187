import json from './holiday-data.json'
import { tns } from 'tiny-slider/src/tiny-slider'

let holiday = document.querySelector('.holiday')
const overlay = document.querySelector('.holiday__overlay')
const overlayContent = document.querySelector('.holiday__overlay__content')
const closeButton = document.querySelector('.holiday__overlay__close > img')
const urlCopied = document.getElementById('urlCopied')
let slider

if (holiday) {
  addModalEventListeners()
  addGiftEventListeners()

  // Read event listeners after fullpage is destroyed because
  // it fucks with the DOM and removes our click handlers
  window.addEventListener('fullpage-destroyed', () => {
    addGiftEventListeners()
  })
}

function addGiftEventListeners(){
  let gifts = Array.from(document.querySelectorAll('.holiday__gift'))

  let header = document.querySelector('.holiday__overlay__content__text-block > h1')
  let address = document.querySelector('.holiday__overlay__content__text-block').querySelector('.address')
  let subheader = document.querySelector('.holiday__overlay__content__text-block').querySelector('.subheader')
  let copy = document.querySelector('.holiday__overlay__content__text-block').querySelector('.copy')
  let giftAndPrice = document.querySelector('.holiday__overlay__content__text-block').querySelector('.gift-and-price')
  let url = document.querySelector('.holiday__overlay__content__text-block > a' )
  let share = document.querySelector('.share')
  let shareIcons = document.querySelector('.holiday__share-modal')
  let grabURL = document.querySelector('.grab-url')

  gifts.forEach((gift)=> {
    gift.addEventListener('click', (e)=> {
      toggleHolidayOverlay(e)
      let num = e.target.innerHTML
      if (header) {
        header.innerText = json["holiday-content"][num - 1]["header"]
      }
      address.innerText = json["holiday-content"][num - 1]["address"]
      subheader.innerText = json["holiday-content"][num - 1]["subheader"]
      copy.innerHTML = null
      json["holiday-content"][num - 1]["copy"].forEach((paragraph)=> {
        copy.innerHTML += `<p>${paragraph}</p>`
      })
      url.href = json["holiday-content"][num - 1]["url-link"]
      url.innerText = json["holiday-content"][num - 1]["url-copy"]
      giftAndPrice.innerText = json["holiday-content"][num - 1]["gift-and-price"]

      let imageFolder = json["holiday-content"][num - 1]["image-folder"]
      let imagePaths = json["holiday-content"][num - 1]["image-paths"]

      if (slider) {
        slider.destroy()
        slider = null
      }

      let imageWindow = document.querySelector('.holiday__overlay__content__img-window')
      imageWindow.innerHTML = ''

      imagePaths.forEach((img, index)=> {
        imageWindow.innerHTML += `<div>
          <img src="/dist/images/holiday/gifts/${imageFolder}/${imagePaths[index]}" alt="">
        </div>`
      })

      slider = tns({
        container: '.my-slider',
        items: 1,
        speed: 400,
        navPosition: 'bottom'
      })
    })
  })

  share.addEventListener('click', ()=> {
    shareIcons.classList.toggle('holiday__share-modal__hidden')
  })

  urlCopied.style.display = "none"

  grabURL.addEventListener('click', (e)=>{
    e.preventDefault()

    if (urlCopied.style.display !== "none") {
      urlCopied.style.display = "none"
    } else {
      let el = document.querySelector('#urlCopied')
      el.value = window.location.href
      el.cols = window.location.href.length
      el.select()
      document.execCommand('copy');
      urlCopied.style.display = "block"
    }
  })
}

function addModalEventListeners() {
  overlay.addEventListener('click', (e)=> {
    toggleHolidayOverlay(e)
  })

  overlayContent.addEventListener('click', (e)=> {
    e.stopPropagation()
  })

  closeButton.addEventListener('click', (e)=> {
    e.stopPropagation()
    toggleHolidayOverlay(e)
  })
}

function toggleHolidayOverlay(e) {
  e.preventDefault()
  overlay.classList.toggle('holiday__overlay__hidden')
}
