import fullpage from 'fullpage.js'
import throttle from 'lodash/throttle'
const LICENSE_KEY = '3C27DDDA-1A084261-95C08F52-D9A0A6DD'

const slides = document.querySelector('.slides')
const footer = document.querySelector('.footer')
let fullpageSlidesApi

function initFullPageSlides() {
  fullpageSlidesApi = new fullpage('.slides', {
    autoScrolling: true,
    scrollBar: true,
    sectionSelector: '.slide-container',
    normalScrollElements: '.holiday__overlay__content__text-block__scroll-container',
    licenseKey: LICENSE_KEY,

    afterRender: function() {
      initFooter()
    },

    afterLoad: function(origin, destination) {
      initFooter()

      if (destination.isLast) {
        footer.style.bottom = '0'
      } else {
        footer.style.bottom = '-6vh'
      }
    }
  })
}

function initFooter() {
  footer.style.position = 'fixed'
  footer.style.bottom = '-6vh'
  footer.style.transition = 'bottom 0.4s'
}

const onResize = throttle(function() {
  if (window.innerWidth > 1200) {
    if (!fullpageSlidesApi) {
      initFullPageSlides()
    }
  } else if (fullpageSlidesApi) {
    fullpageSlidesApi.destroy('all')
    fullpageSlidesApi = null
    footer.style.position = 'relative'
    footer.style.bottom = 'auto'
    const event = new Event('fullpage-destroyed')
    window.dispatchEvent(event)
  }
}, 1000)

if (slides) {
  window.addEventListener('resize', onResize)
  onResize()
}
