const parallax = document.querySelector('.parallax')

if (parallax) {
  addScrollListener()
}

function addScrollListener() {
  const slides = document.querySelectorAll('.parallax__slide')
  clipSlides(slides)

  window.addEventListener('scroll', () => {
    clipSlides(slides)
  })
}

function clipSlides(slides) {
  Array.from(slides).forEach((slide, i) => {
    const rect = slideRect(slide, i)
    slide.style.clip = `rect(${rect.top}vh, ${rect.right}vw, ${rect.bottom}vh, ${rect.left}vw)`
  })
}

function slideRect(slide, i) {
  const slidesScrolled = window.scrollY / window.innerHeight
  const percentThisSlideScrolled = slidesScrolled - (i - 1)

  let top = 100 - (percentThisSlideScrolled * 100)

  top = Math.max(0, top)
  top = Math.min(100, top)

  return {
    top: top,
    right: 100,
    bottom: 100,
    left: 0,
  }
}
